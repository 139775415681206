export const defaultRooms = [
    {
        name: 'New York',
        slug: 'new-york'
    },
    {
        name: 'San Diego',
        slug: 'san-diego'
    },
    {
        name: 'Miami',
        slug: 'miami'
    },
    {
        name: 'Chicago',
        slug: 'chicago'
    },
    {
        name: 'Austin',
        slug: 'austin'
    },
    {
        name: 'Boston',
        slug: 'boston'
    },
    {
        name: '',
        slug: ''
    }
]